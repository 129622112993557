<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg">
   <g id="icon_koningwillemIcollege" transform="translate(-2866 -2882)">
    <g data-name="Group 500" id="Group_500" transform="translate(-456 241)">
     <g data-name="Group 496" id="Group_496" transform="translate(880 23)">
      <g data-name="Group 469" id="Group_469" transform="translate(-228 263)">
       <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(2670 2355)" width="183"/>
      </g>
     </g>
    </g>
    <g data-name="Group 501" id="Group_501">
     <rect fill="#0085ca" data-name="Rectangle 238" height="113" id="Rectangle_238" rx="15" transform="translate(2901 2917)" width="113"/>
     <g data-name="logo (2)" id="logo_2_" transform="translate(2909.41 2927.993)">
      <path d="M33.191,6.332,29.858,16.639h6.666Z" data-name="Path 603" fill="#ee2e2f" id="Path_603" transform="translate(8.175)"/>
      <path d="M38.3,6.332,34.965,16.639h6.667Z" data-name="Path 604" fill="#ee2e2f" id="Path_604" transform="translate(9.783)"/>
      <path d="M43.4,6.332,40.069,16.639h6.669Z" data-name="Path 605" fill="#ee2e2f" id="Path_605" transform="translate(11.39)"/>
      <path d="M13.455,15.9l-.281.188L9.719,24.464V16.209l-.305-.3H4.194l-.3.3V42.016l.3.3h5.22l.305-.3v-9.3l.617-1.592,3.634,10.992.289.209h5.42l.285-.41-5.6-15.115,5.2-10.449-.272-.44Z" data-name="Path 606" fill="#fff" id="Path_606" transform="translate(0 3.014)"/>
      <path d="M23.532,15.523c-5.207,0-7.845,3.179-7.845,9.451v8.673c0,6.081,2.712,9.293,7.845,9.293s7.86-3.258,7.86-9.423V24.973c0-6.27-2.645-9.45-7.86-9.45M21.516,23.67c0-2.329.678-3.508,2.017-3.508s2.033,1.181,2.033,3.508V34.823c0,2.352-.665,3.495-2.033,3.495-1.34,0-2.017-1.175-2.017-3.495V23.67Z" data-name="Path 607" fill="#fff" id="Path_607" transform="translate(3.714 2.893)"/>
      <path d="M38.526,15.894l-.3.305v11.99L33.145,16.081l-.281-.187H29.107l-.3.305V42.018l.3.305h4.487l.305-.305V29.3L38.96,42.131l.284.192h3.613l.3-.305V16.2l-.3-.305Z" data-name="Path 608" fill="#fff" id="Path_608" transform="translate(7.843 3.01)"/>
      <path d="M41.45,15.9l-.3.305V42.016l.3.3h5.217l.305-.3V16.21l-.305-.305Z" data-name="Path 609" fill="#fff" id="Path_609" transform="translate(11.729 3.014)"/>
      <path d="M56.723,15.894l-.305.305v11.99L51.342,16.081l-.281-.187H47.305L47,16.2V42.018l.305.305h4.487l.3-.305V29.3l5.061,12.831.283.192h3.613l.305-.305V16.2l-.305-.305Z" data-name="Path 610" fill="#fff" id="Path_610" transform="translate(13.572 3.01)"/>
      <path d="M74.606,28.555h-8.17l-.305.3V32.6l.305.305h2.649v1.759c0,2.414-.7,3.638-2.076,3.638-1.406,0-2.089-1.144-2.089-3.495V23.668c0-.868,0-3.508,2.089-3.508,1.835,0,2.089,1.914,2.089,3.981v1.807l.3.305h4.99l.305-.305V24.43c0-5.827-2.66-8.907-7.689-8.907-5.253,0-7.916,3.179-7.916,9.452v9.089c0,4.094,1.8,8.878,6.884,8.878a5.843,5.843,0,0,0,4.3-1.976l.465,1.274.287.2h3.584l.305-.3V28.86l-.306-.305Z" data-name="Path 611" fill="#fff" id="Path_611" transform="translate(17.378 2.893)"/>
      <path d="M20.32,37.715l-.266.242L18.986,48.782,17.605,37.949l-.264-.234H14.372l-.264.234L12.7,48.828l-1.022-10.87-.264-.243H7.97l-.264.3,2.768,20.01.263.231H14.2l.263-.235,1.384-11.009,1.411,11.01.264.234h3.457l.264-.231,2.8-20.009-.263-.305Z" data-name="Path 612" fill="#fff" id="Path_612" transform="translate(1.201 9.88)"/>
      <path d="M21.169,37.715l-.267.267v20.01l.267.268h4.047l.267-.268V37.982l-.267-.267Z" data-name="Path 613" fill="#fff" id="Path_613" transform="translate(5.356 9.88)"/>
      <path d="M30.369,54.946V37.982l-.267-.267H26.057l-.267.267v20.01l.267.267h8.026l.267-.267v-2.78l-.267-.267Z" data-name="Path 614" fill="#fff" id="Path_614" transform="translate(6.894 9.88)"/>
      <path d="M37.919,54.946V37.982l-.266-.267H33.607l-.267.267v20.01l.267.267h8.024l.27-.267v-2.78l-.27-.267Z" data-name="Path 615" fill="#fff" id="Path_615" transform="translate(9.271 9.88)"/>
      <path d="M41.158,37.715l-.267.267v20.01l.267.268h8.348l.267-.268v-2.78l-.267-.267H45.47V49.321h2.78l.266-.267V46.21l-.266-.267H45.47V41.027h4.035l.267-.266v-2.78l-.267-.267Z" data-name="Path 616" fill="#fff" id="Path_616" transform="translate(11.648 9.88)"/>
      <path d="M58.3,37.715l-.262.222L56.012,49.7,54.132,37.94l-.264-.225H49.021l-.268.267V57.991l.268.268h2.99l.266-.267.071-11.175,1.945,11.219.263.222h3.025l.26-.229c.33-2.159.756-4.636,1.169-7.03.221-1.285.446-2.591.657-3.863l.068,10.854.267.267h2.981l.267-.268V37.982l-.267-.267Z" data-name="Path 617" fill="#fff" id="Path_617" transform="translate(14.123 9.88)"/>
      <path d="M67.5,37.715H63.456l-.268.267v20.01l.268.268H67.5l.268-.268V37.982Z" data-name="Path 618" fill="#fff" id="Path_618" transform="translate(18.668 9.88)"/>
      <path d="M21.03,54.972c-2.723,0-4.1,1.672-4.1,4.971V64.41c0,3.241,1.419,4.957,4.1,4.957,2.547,0,3.918-1.679,3.968-4.858L25,63.37l-.191-.192H22.144l-.191.191v1.388a3.229,3.229,0,0,1-.423,1.929.676.676,0,0,1-.5.195c-.247,0-.994,0-.994-1.793V59.262c0-1.2.334-1.8.994-1.8.8,0,.91,1.016.91,2.116V60.82l.192.192h2.682L25,60.82V59.658c0-3.066-1.374-4.686-3.973-4.686" data-name="Path 619" fill="#fff" id="Path_619" transform="translate(4.104 15.312)"/>
      <path d="M27.982,54.972c-2.741,0-4.131,1.672-4.131,4.971v4.533c0,3.2,1.428,4.89,4.131,4.89s4.142-1.716,4.142-4.957V59.943c0-3.3-1.394-4.971-4.142-4.971m1.031,10.12c0,1.208-.337,1.8-1.031,1.8-.678,0-1.023-.606-1.023-1.8V59.261c0-1.2.344-1.8,1.023-1.8s1.031.6,1.031,1.8v5.831Z" data-name="Path 620" fill="#fff" id="Path_620" transform="translate(6.284 15.312)"/>
      <path d="M31.131,55.172l-.192.189v13.49l.192.189h5.41l.191-.189V66.976l-.191-.189H34.05V55.361l-.192-.189Z" data-name="Path 621" fill="#fff" id="Path_621" transform="translate(8.515 15.375)"/>
      <path d="M36.218,55.172l-.189.189v13.49l.189.189H41.63l.191-.189V66.976l-.191-.189H39.139V55.361l-.192-.189Z" data-name="Path 622" fill="#fff" id="Path_622" transform="translate(10.118 15.375)"/>
      <path d="M41.309,55.172l-.192.189v13.49l.192.189h5.627l.191-.189V66.976l-.191-.189h-2.71V63.016H46.09l.192-.191V60.907l-.192-.191H44.227V57.426h2.71l.191-.192V55.361l-.191-.189Z" data-name="Path 623" fill="#fff" id="Path_623" transform="translate(11.72 15.375)"/>
      <path d="M50.156,61.783l-.192.191V63.93l.192.191h1.352v.889c0,1.241-.355,1.87-1.053,1.87-.263,0-1.061,0-1.061-1.793V59.262c0-1.8.8-1.8,1.061-1.8.753,0,1.062.592,1.062,2.05v.944l.191.192h2.607l.191-.192V59.66c0-3.066-1.4-4.686-4.05-4.686-2.766,0-4.169,1.672-4.169,4.971V64.7c0,2.156.951,4.673,3.63,4.673a3.087,3.087,0,0,0,2.233-1l.226.617.179.124H54.43l.191-.191V61.977l-.191-.191H50.156Z" data-name="Path 624" fill="#fff" id="Path_624" transform="translate(13.347 15.313)"/>
      <path d="M53.745,55.172l-.191.189v13.49l.191.189h5.627l.191-.189V66.976l-.191-.189H56.664V63.016h1.862l.191-.191V60.907l-.191-.191H56.664V57.426h2.709l.191-.192V55.361l-.191-.189Z" data-name="Path 625" fill="#fff" id="Path_625" transform="translate(15.635 15.375)"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>